import request from '@/request';

const part_url = "/share/dictionary/";
export default {
    /**
     * 获取所有的字典项
     */
    getPageList(params) {
        let url = part_url + "item/getPageList";
        return request.get(url,params, {});
    },
    /**
     * 删除
     */
    deleteById(params) {
        let url = part_url + "item/delete";
        return request.get(url,params, {});
    },
    /**
     * 获取层级字典子项 
     */
    tree(params) {
        let url = part_url + "item/getTreeByDictionaryId";
        return request.get(url,params, {});
    },
    /**
     * 查询详情 
     */
    getById(params) {
        let url = part_url + "item/getById";
        return request.get(url,params, {});
    },
    /**
     * 修改 
     */
    update(params) {
        let url = part_url + "item/update";
        return request.post(url,{},params);
    },
    /**
     * 保存 
     */
    save(params) {
        let url = part_url + "item/save";
        return request.post(url,{},params);
    },
    /**
     * 查询dictionaryItem 
     */
    getTreeByDictionaryId(params) {
        let url = part_url + "item/getTreeByDictionaryId";
        return request.get(url,params,{});
    },
    /**
     * 查询dictionaryItemList 
     */
    getDictionaryItem(params) {
        let url = part_url + "item/getDictionaryItem";
        return request.get(url,params,{});
    },
    getItemByDictionaryName(dictionaryName) {
        let url = part_url + "item/getItemByDictionaryName";
        return request.get(url,{
            dictionaryName
        },{});
    },

}